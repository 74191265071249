import { createRouter, createWebHistory } from 'vue-router'
import EventsView from '@/views/EventsView.vue'
import PlacesView from '@/views/PlacesView.vue'
import LoginView from '@/views/LoginView.vue'
import RegistrationView from "@/views/RegistrationView.vue";
import ProfileView from "@/views/ProfileView.vue";
import store from "@/store";
import ActivationView from "@/views/ActivationView.vue";
import RecoverPasswordView from "@/views/RecoverPasswordView.vue";

const routes = [
  {
    path: '/',
    name: 'events',
    component: EventsView
  },
  {
    path: '/places',
    name: 'places',
    component: PlacesView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView, // () => import('../views/ProfileView.vue') // for chunking
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      layout: 'SimpleLayout',
      requiresAuth: false
    }
  },
  {
    path: '/registration',
    name: 'registration',
    component: RegistrationView,
    meta: {
      layout: 'SimpleLayout',
      requiresAuth: false
    }
  },
  {
    path: '/activation',
    name: 'activation',
    component: ActivationView,
    meta: {
      layout: 'SimpleLayout',
      requiresAuth: false
    }
  },
  {
    path: '/password/recover',
    name: 'passwordRecovery',
    component: RecoverPasswordView,
    meta: {
      layout: 'SimpleLayout',
      requiresAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters["auth/isAuthenticated"];
  let requireAuth = to.matched.some((e) => "undefined" === typeof e.meta.requiresAuth || e.meta.requiresAuth);
  if (requireAuth && !isAuthenticated) {
    next({name: 'login'});
  } else if (!requireAuth && isAuthenticated) {
    next('/')
  } else {
    next()
  }
});

export default router
