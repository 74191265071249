<template>
  <div class="form-container">
    <form class="mb-5" @submit="submit">
      <div class="alert alert-danger small p-2 w-100" v-if="errorMessage">
        {{ errorMessage }}
      </div>
      <div class="alert alert-success small p-2 w-100" v-else-if="successMessage">
        {{ successMessage }}
      </div>
      <div class="mb-3">
        <input
          v-model="formData.email"
          type="text"
          placeholder="E-mail"
          class="form-control"
          :class="{ 'is-invalid': formErrors.email }"
          @change="formErrors.email = undefined"
        />
        <div v-if="formErrors.email" class="invalid-feedback">
          {{ formErrors.email }}
        </div>
      </div>
      <div class="mb-3">
        <input
          v-model="formData.password"
          type="password"
          placeholder="Hasło"
          class="form-control"
          :class="{ 'is-invalid': formErrors.password }"
          @change="formErrors.password = undefined"
        />
        <div v-if="formErrors.password" class="invalid-feedback">
          {{ formErrors.password }}
        </div>
      </div>
      <div class="mb-3">
        <input
          v-model="formData.passwordRepeated"
          type="password"
          placeholder="Powtórz hasło"
          class="form-control"
          :class="{ 'is-invalid': formErrors.passwordRepeated }"
          @change="formErrors.passwordRepeated = undefined"
        />
        <div v-if="formErrors.passwordRepeated" class="invalid-feedback">
          {{ formErrors.passwordRepeated }}
        </div>
      </div>
      <div class="mb-3">
        <input
          v-model="formData.firstName"
          type="text"
          placeholder="Imię"
          class="form-control"
          :class="{ 'is-invalid': formErrors.firstName }"
          @change="formErrors.firstName = undefined"
        />
        <div v-if="formErrors.firstName" class="invalid-feedback">
          {{ formErrors.firstName }}
        </div>
      </div>
      <div class="form-check mx-4 mb-3 text-start text-light" >
        <input
          id="rules"
          v-model="formData.isAccepted"
          type="checkbox"
          class="form-check-input"
          :class="{ 'is-invalid': formErrors.isAccepted }"
          @change="formErrors.isAccepted = undefined"
        />
        <label class="form-check-label" for="rules">Akceptuję regulamin</label>
        <div v-if="formErrors.isAccepted" class="invalid-feedback">
          {{ formErrors.isAccepted }}
        </div>
      </div>
      <div class="mb-3 mt-5 text-center">
        <button type="submit" class="btn btn-outline-success w-100">Rejestruj</button>
      </div>
      <div class="mb-3 text-center">
        <router-link :to="{name: 'login'}" class="btn btn-dark btn-sm w-100">Powrót do logowania</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { default as api } from '@/services/api';
import { object, string, bool, ref } from 'yup';

const registerSchema = object({
  firstName: string().required('Imię nie może być puste'),
  email: string().email('Nieprawidłowy adres email').required('Adres e-email nie może być pusty'),
  password: string().min(7, 'Hasło musi mieć przynajmniej 7 znaków').required('Hasło nie może być puste'),
  passwordRepeated: string().required('Hasło nie może być puste').oneOf([ref('password'), null], 'Hasła muszą się zgadzać'),
  isAccepted: bool().oneOf([true], 'Regulamin musi zostać zaakceptowany')
});

export default {
  name: 'RegistrationView',
  data() {
    return {
      formErrors: {},
      formData: this.initFormData(),
      errorMessage: '',
      successMessage: ''
    }
  },
  methods: {
    initFormData() {
      return {
        email: '',
        password: '',
        passwordRepeated: '',
        firstName: '',
        isAccepted: false,
      }
    },
    validate() {
      this.formErrors = {};

      try {
        registerSchema.validateSync(this.formData, { abortEarly: false });
      } catch (error) {
        error.inner.forEach(err => {
          this.formErrors[err.path] = err.message;
        });
      }

      const errors = Object.values(this.formErrors);
      if (errors.length > 0) {
        return 'Formularz zawiera błędy';
      }

      return null;
    },
    submit(e) {
      e.preventDefault();
      this.successMessage = '';
      this.errorMessage = this.validate();
      if (this.errorMessage) {
        return;
      }
      api.registerUser(
        this.formData.email,
        this.formData.password,
        this.formData.firstName
      ).then(() => {
        this.formData = this.initFormData();
        this.successMessage = 'Pomyślnie zarejestrowano konto. Sprawdź e-mail aby je aktywować.'
      }).catch(async (error) => {
        const data = await error.json();
        this.errorMessage = data.message;
      });
    }
  }
}
</script>