import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import router from './router'
import {FontAwesomeIcon} from "./icons"
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min'
import './styles/app.scss'

createApp(App)
  .component('fa-icon', FontAwesomeIcon)
  .use(router)
  .use(store)
  .mount('#app')
