const ORIENTATION_PORTRAIT = 'portrait';
const ORIENTATION_LANDSCAPE = 'landscape';

const device = {
  namespaced: true,
  state: () => ({
    orientation: ORIENTATION_PORTRAIT
  }),
  mutations: {
    setOrientation(state, orientation) {
      state.orientation = orientation;
    },
  },
  actions: {
    setOrientationPortrait({commit}) {
      commit('setOrientation', ORIENTATION_PORTRAIT);
    },
    setOrientationLandscape({commit}) {
      commit('setOrientation', ORIENTATION_LANDSCAPE);
    },
  },
  getters: {
    isOrientationPortrait(state) {
      return state.orientation === ORIENTATION_PORTRAIT;
    },
    isOrientationLandscape(state) {
      return state.orientation === ORIENTATION_LANDSCAPE;
    },
  }
}

export default device