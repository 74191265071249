<template>
  <div class="ui-loader align-items-center justify-content-center text-center d-flex h-100">
    <div class="ui-loader-bg w-100 h-100 position-absolute" :style="{backgroundColor: bgColor, opacity: bgOpacity}"></div>
    <div class="spinner-border" role="status"></div>
  </div>
</template>

<script>
export default {
  name: "UiLoader",
  props: {
    bgColor: {
      type: String || null,
      default: null
    },
    bgOpacity: {
      type: Number,
      default: 1.0,
    },
  }
}
</script>

<style lang="scss" scoped>
.ui-loader {
  position: relative;
  z-index: 9999;
}
</style>

