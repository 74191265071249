<template>
  <div class="form-container">
    <div>
      <form class="mb-5" @submit="submitLoginUser">
        <div class="alert alert-danger small w-100 p-2" v-if="errorMessage">
          {{ errorMessage }}
        </div>
        <div class="mb-3">
          <input type="text" placeholder="Login" class="form-control" aria-describedby="emailHelp" v-model="login">
        </div>
        <div class="mb-3">
          <input type="password" placeholder="Hasło" class="form-control" id="inputPassword" aria-describedby="emailHelp" v-model="password">
        </div>
        <div class="mb-3 text-center">
          <button type="submit" class="btn btn-dark w-100">Zaloguj się</button>
        </div>
        <div>
          <router-link :to="{name: 'passwordRecovery'}" class="btn btn-sm text-white w-100">Odzyskiwanie hasła</router-link>
        </div>
      </form>
    </div>
    <div>
      <router-link :to="{name: 'registration'}" class="btn btn-outline-success mb-3 w-100">Utwórz konto</router-link>
    </div>
    <div>
      <a class="btn btn-sm btn-dark w-100" @click="submitLoginGuest">Kontynuuj jako Gość</a>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script>
import {mapActions} from "vuex";
import {default as api} from '@/services/api';

export default {
  name: 'LoginView',
  data() {
    return {
      login: '',
      password: '',
      errorMessage: '',
      successMessage: '',
    }
  },
  methods: {
    ...mapActions({
      loginGuest: 'auth/loginGuest',
      loginUser: 'auth/loginUser'
    }),
    submitLoginUser(e) {
      e.preventDefault();
      this.errorMessage = '';
      api.loginUser(this.login, this.password)
        .then((data) => {
            if (data.user) {
              this.loginUser(data);
              this.$router.push("/");
            } else {
              this.errorMessage = 'Wystapił problem podczas logowania. Spróbuj ponownie.';
            }
        })
        .catch(() => {
          this.errorMessage = 'Nieprawodłowy login lub hasło';
        })
    },
    submitLoginGuest() {
      this.loginGuest();
      this.$router.push("/")
    }
  }
}
</script>