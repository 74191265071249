<template>
  <div class="places">
    <div class="row h-100 m-0 p-0">
      <div :class="'p-0 ' + (isOrientationPortrait ? 'h-50 col-12' : 'h-100 col-8')">
        <places-map
          :place-markers="placesMarkers"
          @filter-places="filterPlaces"
        />
      </div>
      <div :class="'p-0 overflow-scroll-y ' + (isOrientationPortrait ? 'h-50 col-12' : 'h-100 col-4')">
        <ui-loader v-if="loading" />
        <ul class="list-group" v-else-if="filteredPlaces.length > 0">
          <li class="list-group-item py-3 rounded-0" v-for="(place, i) in filteredPlaces" :key="i"
            :style="'background-position: right center; background-image: linear-gradient(90deg, rgba(254,254,254,1) 60%, rgba(255,255,255,0.3) 100%), url('+place.thumbnailUrl+');'">
            <h5>{{ place.locationName }}</h5>
            <div class="row">
              <div class="col-12">
                {{ place.address }}
              </div>
              <div class="col-12">
                <button
                  type="button"
                  class="btn btn-sm btn-dark mt-3 w-100"
                  @click="setPlaceContext(i)"
                >
                  Pokaż Szczegóły
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div v-else class="p-3 text-center">
          Brak miejscówek do wyświetlenia.
        </div>
      </div>
    </div>
    <ui-modal v-model="placeModalShow">
      <template #body v-if="placeContext">
        <div class="row">
          <div class="col-10">
            <h2>{{ placeContext.locationName }}</h2>
          </div>
          <div class="col-2 text-end pl-0">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-lg-3 col-md-12" v-if="placeContext.thumbnailUrl">
            <div class="place-logo mb-2" :style="'background-image: url(' + placeContext.thumbnailUrl + ')'"></div>
          </div>
          <div class="col">
            <div class="event-description small" v-html="nl2br(placeContext.description || '<i>Brak opisu.</i>')"></div>
          </div>
        </div>
      </template>
    </ui-modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {default as api} from '@/services/api';
import UiModal from "@/components/Ui/UiModal.vue";
import UiLoader from "@/components/Ui/UiLoader.vue";
import PlacesMap from "@/components/Places/PlacesMap.vue";

export default {
  name: 'PlacesView',
  components: {
    UiLoader,
    UiModal,
    PlacesMap,
  },
  computed: {
    ...mapGetters({
      isOrientationPortrait: 'device/isOrientationPortrait'
    }),
    filteredPlaces() {
      if (this.filterPlacesKeys.length > 0) {
        return this.places.filter((e, key) => this.filterPlacesKeys.includes(key));
      }
      return this.places;
    }
  },
  data() {
    return {
      placeContext: null,
      placeModalShow: false,
      placesMarkers: [],
      places: [],
      loading: true,
      filterPlacesKeys: [],
    }
  },
  mounted() {
    this.loadPlaces().finally(() => this.loading = false);
  },
  methods: {
    async loadPlaces() {
      const data = await api.getPlaces();

      this.placesMarkers = data.placeMarkers || [];
      this.places = data.places || [];
    },
    filterPlaces(placeKeys) {
      this.filterPlacesKeys = placeKeys;
    },
    setPlaceContext(placeKey) {
      this.placeModalShow = true;
      this.placeContext = this.filteredPlaces.at(placeKey);
    },
    nl2br(description) {
      return description
        .replace(/<div[^>]*>\s+<\/div>/g, '<div></div>')
        .replace(/<\/div>\s+<div/g, '</div><div')
        .replace(/\r\n/g, '<br>\r\n');
    }
  }
}
</script>

<style lang="scss" scoped>
.places {
  height: calc(100vh - 64px);
  position: relative;
  overflow: hidden;
  .list-group-item {
    background-size: auto;
    background-position: center center;
    background-repeat: no-repeat;
    border: none;
    border-bottom: 1px solid #000;
  }
}

.place-logo {
  height: 180px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
}
</style>
