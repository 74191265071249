<template>
  <component :is="layout || 'div'">
    <slot />
  </component>
</template>

<script setup>
import layouts from '.'
import router from "@/router";
import {shallowRef, provide} from "vue";

const layout = shallowRef('div')

router.afterEach((to) => {
  layout.value = layouts[to?.meta?.layout || 'DefaultLayout'] || 'div';
});

provide('app:layout', layout)
</script>