import { default as store } from '@/store'
import { default as gql } from 'nanographql'

const API_PROTOCOL = process.env.VUE_APP_API_PROTOCOL || 'https'
const API_HOST = process.env.VUE_APP_API_HOST || 'app.toczersi.com'
const API_PATH = process.env.VUE_APP_API_PATH || ''

const baseUrl = () => API_PROTOCOL + '://' + API_HOST + API_PATH;

const headers = (contentType = 'application/json') => {
  const token = store.getters['auth/getToken'];
  let headers = {
    'Accept': 'application/json',
  }
  if (contentType) {
    headers['Content-Type'] = contentType;
  }
  if (token) {
    headers['X-Token'] = token.value;
  }
  return headers;
}

const jsonResponseHandler = (response, resolve, reject) => {
  if (!response.ok) {
    reject(response);
  } else {
    response.json()
        .then((data) => {
          if (data.errors) {
            // handle graphql errors
            reject({
              json: async () => {
                return {
                  message: data.errors[0].message
                }
              }
            })
          } else {
            resolve(data.data ? data.data : data)
          }
        })
        .catch((e) => reject(e))
    ;
  }
}

const graphql = async (query, variables = {}) => {
  return new Promise((resolve, reject) => {
    fetch(baseUrl() + '/graphql', {
      method: 'POST',
      headers: headers(),
      body: (gql(query))(variables),
    }).then((response) => {
      jsonResponseHandler(response, resolve, reject);
    }).catch((e) => reject(e));
  })
}

const post = async (path, data = {}) => {
  return new Promise((resolve, reject) => {
    fetch(baseUrl() + path, {
      method: 'POST',
      headers: headers(data instanceof FormData ? false : 'application/json'),
      body: data instanceof FormData ? data : JSON.stringify(data),
    }).then((response) => {
      jsonResponseHandler(response, resolve, reject);
    }).catch((e) => reject(e));
  })
}

const api = {
  async getEvents(period){
    return graphql(`
      query Events ($period: EVENT_PERIOD!) {
        events (period: $period) {
          id,
          startAt,
          endAt,
          title,
          locationName,
          address,
          description,
          thumbnailUrl
        },
        eventMarkers (period: $period) {
          locationName,
          addressLatitude,
          addressLongitude,
          address,
          events
        }
      }`, {
        period
      }
    )
  },
  async getPlaces(){
    return graphql(`
      query Places {
        places {
          id,
          locationName,
          address,
          description,
          thumbnailUrl
        },
        placeMarkers {
          locationName,
          addressLatitude,
          addressLongitude,
          address
        }
      }`
    )
  },
  async changeStatus(status, description) {
    return graphql(`
      mutation ChangeUserStatus ($status: USER_STATUS!, $description: String) {
          changeUserStatus (status: $status, description: $description)
      }`, {
        status,
        description
      }
    )
  },
  async updateProfile(email, firstName, lastName, joinDate, joinDescription) {
    return graphql(`
      mutation UpdateUser ($updateUserInput: UpdateUserInput!) {
        updateUser (updateUserInput: $updateUserInput)
      }`, {
        updateUserInput: {
          email,
          firstName,
          lastName,
          joinDate,
          joinDescription
        }
      }
    )
  },
  async passwordRecover(email) {
    return graphql(`
      mutation RecoverUserPassword ($email: String!) {
        recoverUserPassword (email: $email)
      }`, {
        email
      }
    )
  },
  async passwordChange(token, password) {
    return graphql(`
      mutation ChangeUserPassword ($token: String!, $password: String!) {
        changeUserPassword (token: $token, password: $password)
      }`, {
        token,
        password
      }
    )
  },
  async registerUser(email, password, firstName) {
     return graphql(`
      mutation RegisterUser ($registerUserInput: RegisterUserInput!) {
        registerUser (registerUserInput: $registerUserInput)
      }`, {
        registerUserInput: {
          email,
          password,
          firstName
        }
      }
    )
  },
  async activateUser(activationToken) {
    return graphql(`
      mutation ActivateUser ($activationToken: String!) {
        activateUser (activationToken: $activationToken)
      }`, {
        activationToken
      }
    )
  },
  async loginUser(login, password) {
    return post('/tokens', {
      login,
      password
    })
  },
  async refreshToken() {
    return graphql(`
      mutation RefreshUserToken {
        refreshUserToken {
          token,
          tokenExpiresIn,
          user {
            id,
            qr,
            login,
            email,
            no,
            status,
            statusDescription,
            firstName,
            lastName,
            roles,
            joinDate,
            joinDescription,
            photo {
              id,
              url
            }
          }
        }
      }`
    )
  },
  async changeUserPhoto(fileId) {
    return graphql(`
      mutation ChangeUserPhoto ($fileId: String!) {
        changeUserPhoto (fileId: $fileId)
      }`, {
        fileId
      }
    )
  },
  async uploadFile(type, fileObject) {
    let data = new FormData();
    data.set('type', type);
    data.set('file', fileObject);
    return post('/files', data);
  },
}

export default api