<template>
  <nav class="navbar fixed-top navbar-dark bg-black">
    <div class="container-fluid">
      <a ref="navbarToggler"
         class="navbar-toggler"
         data-bs-toggle="collapse"
         data-bs-target="#navbarNavAltMarkup"
         aria-controls="navbarNavAltMarkup"
         aria-expanded="false"
         aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </a>
      <div class="avatar-container">
        <div class="avatar logo-bg dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
          <span v-if="isUser" :class="indicatorClass" class="indicator border-black border border-2 rounded-circle"></span>
        </div>
        <div class="dropdown" v-if="isUser">
          <div class="dropdown-menu dropdown-menu-end px-3 py-2">
            <h6 class="dropdown-header px-0 pt-0 pb-0">Status</h6>
            <div class="mb-2">
              <input @change="() => updateStatus()" v-model="statusDescription"
                     maxlength="60"
                     type="text" class="form-control-sm status-description px-0 pt-0" placeholder="Opis" autocomplete="off" />
            </div>
            <div class="d-flex justify-content-between">
              <div class="label">{{ isStatusAvailable ? 'Dostępny' : 'Niedostępny' }}</div>
              <div class="form-check form-switch rounded m-0 p-0 py-1">
                <input @change="toggleStatus" class="form-check-input rounded-5 m-0 p-0 px-1"
                       type="checkbox" role="switch" :class="isStatusAvailable ? 'bg-success' : ''" :checked="isStatusAvailable">
              </div>
            </div>
            <hr class="dropdown-divider">
            <h6 class="dropdown-header px-0 pt-0">Lokalizacja</h6>
            <div class="d-flex justify-content-between mb-2">
              <div class="label">{{ isGeolocation ? 'Wł.' : 'Wył.' }}</div>
              <div class="form-check form-switch rounded m-0 p-0 py-1">
                <input @change="isGeolocation = !isGeolocation" class="form-check-input rounded-5 m-0 p-0 px-1" :class="isGeolocation ? 'bg-success' : ''" type="checkbox" role="switch" :checked="isGeolocation">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup" >
        <div class="navbar-nav">
          <router-link :to="{name: 'events'}" class="nav-link" @click="toggleMenu">Wydarzenia</router-link>
          <router-link :to="{name: 'places'}" class="nav-link" @click="toggleMenu">Miejscówki</router-link>
          <router-link :to="{name: 'profile'}" class="nav-link" @click="toggleMenu" v-if="isUser">Profil</router-link>
          <a href="#" class="nav-link" @click="logout">Wyloguj</a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { default as api } from '@/services/api';
import {mapGetters, mapActions} from "vuex";

export default {
  name: 'TopBar',
  data: () => {
    return {
      isGeolocation: true,
      statusDescription: null,
    };
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      updateUserStatus: 'auth/updateStatus',
    }),
    ...mapGetters({
      getUser: 'auth/getUser',
      getUserStatus: 'auth/getUserStatus',
    }),
    toggleMenu() {
      this.$refs.navbarToggler.click();
    },
    toggleStatus() {
      this.updateStatus(this.isStatusAvailable ? 'UNAVAILABLE' : 'AVAILABLE');
    },
    updateStatus(status) {
      if (!status) {
        status = this.getUserStatus();
      }
      this.updateUserStatus({status, description: this.statusDescription});
      api.changeStatus(status, this.statusDescription);
    }
  },
  mounted() {
    this.statusDescription = this.userStatusDescription;
  },
  computed: {
    ...mapGetters({
      isStatusAvailable: 'auth/isUserStatusAvailable',
      isUser: 'auth/isUser',
      user: 'auth/getUser',
      userStatusDescription: 'auth/getUserStatusDescription',
    }),
    indicatorClass() {
      if (!this.user) {
        return null;
      }
      if (this.isStatusAvailable) {
        return 'bg-success';
      }
      return 'bg-danger';
    },
  },
  watch: {
    userStatusDescription(after) {
      this.statusDescription = after;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

nav {
  min-height: 64px;

  .navbar-toggler-icon {
    cursor: pointer;
  }

  a.active {
    color: #fff;
    border-right: 4px solid #00ce1b;
  }

  .avatar-container {
    .avatar {
      position: relative;
      height: 48px;
      width: 48px;
      padding: 0;

      .indicator {
        position: absolute;
        bottom: 0;
        left: -5px;
        padding: 0.45rem;
      }

      &.dropdown-toggle::after {
        display: none;
      }
    }
    .dropdown-menu {
      margin-top: 0.45rem;
      width: 230px;
      .label {
        line-height: 1.2;
      }
      .status-description, .status-description:focus {
        width: 100%;
        font-size: 0.75em;
        border-left: none;
        border-right: none;
        border-top: none;
        outline: none;
        border-color: #001;
      }

      .status-description:focus {
        border-bottom-color: $success;
      }
    }
  }
}
</style>