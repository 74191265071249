<template>
  <div
    v-if="showInstallPopup"
    class="install-notify alert bg-black border-white text-white fixed-bottom mx-auto text-center p-2"
  >
    <div class="d-flex align-items-center justify-content-start">
      <div >
        <div class="logo logo-bg me-2"></div>
      </div>
      <div class="text-center w-100">
        <div class="mb-1"><b>Aplikacja Toczersi</b></div>
        <div>
          <a href="#" class="btn btn-sm bg-white text-black w-75" @click="install" ><b>Zainstaluj aplikację</b></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { detect } from 'detect-browser';

export default {
  name: "InstallNotification",
  data() {
    return {
      browser: null,
      installPrompt: null,
      showInstallPopup: false
    }
  },
  created() {
    this.browser = detect();

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.installPrompt = e;
      this.showInstallPopup = true;
    });

    window.addEventListener('appinstalled', () => {
      this.showInstallPopup = false;
      this.installPrompt = null;
    });
  },
  methods: {
    async install() {
      this.showInstallPopup = false;
      this.installPrompt.prompt();
      const { outcome } = await this.installPrompt.userChoice;
      console.log(`User response to the install prompt: ${outcome}`);
      this.installPrompt = null;
    }
  }
}
</script>

<style lang="scss" scoped>
  .install-notify {
    max-width: 300px;
  }

  .logo {
    width: 64px;
    height: 64px;
  }
</style>