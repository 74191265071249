<template>
  <div
    v-if="updateExists"
     class="update-notify alert bg-black border-white text-white fixed-bottom mx-auto text-center p-2"
  >
    <div class="d-flex align-items-center justify-content-start">
      <div >
        <div class="logo logo-bg me-2"></div>
      </div>
      <div class="text-center w-100">
        <div class="mb-1"><b>Aktualizacja aplikacji</b></div>
        <div class="small mb-2">Pobrano najnowszą wersję aplikacji.</div>
        <div>
          <a href="#" class="btn btn-sm bg-white text-black w-75" @click="refreshApp" ><b>Przeładuj</b></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import update from '@/mixins/update'

export default {
  name: 'UpdateNotification',
  mixins: [update],
}
</script>

<style lang="scss" scoped>
.update-notify {
  max-width: 400px;
}

.logo {
  width: 64px;
  height: 64px;
}
</style>