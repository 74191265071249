<template>
  <div class="d-flex align-items-center justify-content-center full-height bg-black p-3">
    <div class="text-center">
      <div class="logo logo-bg mb-3"></div>
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .full-height {
    min-height: 100vh;
  }

  .logo {
    width: 100%;
    height: 150px;
  }
</style>
<script setup>
</script>