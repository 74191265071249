<template>
  <app-layout>
    <router-view />
  </app-layout>
  <update-notification />
  <install-notification />
</template>

<script>
import {default as api} from '@/services/api';
import UpdateNotification from '@/components/UpdateNotification.vue';
import InstallNotification from '@/components/InstallNotification.vue';
import {mapActions, mapGetters} from "vuex";

import AppLayout from "@/layouts/AppLayout.vue";

const tokenRefreshRate = (process.env.VUE_APP_TOKEN_REFRESH_RATE || 60) * 1000;

export default {
  name: 'App',
  components: {
    AppLayout,
    InstallNotification,
    UpdateNotification,
  },
  data() {
    return {
      watchSessionHandler: null,
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      isGuest: 'auth/isGuest',
      token: "auth/getToken",
    }),
  },
  methods: {
    ...mapActions({
      setOrientationPortrait: 'device/setOrientationPortrait',
      setOrientationLandscape: 'device/setOrientationLandscape',
      loginUser: 'auth/loginUser',
      logoutUser: 'auth/logout',
    }),
    setOrientation() {
      if (document.documentElement.clientWidth >= document.documentElement.clientHeight) {
        this.setOrientationLandscape();
      } else {
        this.setOrientationPortrait();
      }
    },
    isTokenExpired() {
      if (this.isGuest) {
        return false;
      }
      if (this.token) {
        return new Date(this.token.expiresAt) < new Date();
      }
      return true;
    },
    logoutHandler() {
      this.logoutUser();
      this.$router.push({name: 'login'});
    },
    refreshSession() {
      if (this.isGuest || !this.isAuthenticated || !this.token) {
        setTimeout(this.refreshSession, tokenRefreshRate);
      } else {
        api.refreshToken()
          .then((data) => {
            this.loginUser(data.refreshUserToken);
          })
          .catch(() => {
            this.logoutHandler();
          })
          .finally(() => {
            setTimeout(this.refreshSession, tokenRefreshRate);
          });
      }
    },
    watchSession() {
      if (!this.watchSessionHandler) {
        this.watchSessionHandler = setInterval(() => {
          if (!this.token) {
            return;
          }
          if (this.isTokenExpired()) {
            this.logoutHandler();
          }
        }, 1000);

        this.refreshSession();
      }
    },
    clearWatchSession() {
      if (this.watchSessionHandler) {
        clearInterval(this.watchSessionHandler);
        this.watchSessionHandler = null;
      }
    }
  },
  beforeCreate() {
    this.$store.commit('initStore');
  },
  mounted() {
    this.watchSession();
    this.setOrientation();
    window.addEventListener('resize', this.setOrientation);
  },
  unmounted() {
    window.removeEventListener('resize', this.setOrientation);
    this.clearWatchSession();
  },
}
</script>
