<template>
  <div class="map">
    <l-map ref="map"
      v-model:zoom="zoom"
      :options="{attributionControl: false}"
      :min-zoom="5"
      :center="position"
    >
      <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        layer-type="base"
        name="OpenStreetMap"
      ></l-tile-layer>

      <l-control position="topleft">
        <div class="leaflet-bar">
          <button class="btn btn-sm bg-white border-0" @click="locate" :disabled="isLocating">
            <fa-icon icon="fa-solid fa-crosshairs" />
          </button>
        </div>
      </l-control>

      <l-marker
        v-for="(marker, key) in filteredPlaceMarkers"
        :key="'zoom-' + zoom + '-' + key"
        :ref="'marker-' + key"
        :lat-lng="[marker.addressLatitude, marker.addressLongitude]"
        @popupopen="filterPlaces([key])"
        @popupclose="filterPlaces([])"
      >
        <l-popup :ref="'popup' + key">
          <h6>{{ marker.locationName }}</h6>
          <div>
            {{ marker.address }}
          </div>
        </l-popup>
        <l-icon
          icon-url="/img/icons/marker-gold.png"
          shadow-url="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAApCAQAAAACach9AAACMUlEQVR4Ae3ShY7jQBAE0Aoz/f9/HTMzhg1zrdKUrJbdx+Kd2nD8VNudfsL/Th///dyQN2TH6f3y/BGpC379rV+S+qqetBOxImNQXL8JCAr2V4iMQXHGNJxeCfZXhSRBcQMfvkOWUdtfzlLgAENmZDcmo2TVmt8OSM2eXxBp3DjHSMFutqS7SbmemzBiR+xpKCNUIRkdkkYxhAkyGoBvyQFEJEefwSmmvBfJuJ6aKqKWnAkvGZOaZXTUgFqYULWNSHUckZuR1HIIimUExutRxwzOLROIG4vKmCKQt364mIlhSyzAf1m9lHZHJZrlAOMMztRRiKimp/rpdJDc9Awry5xTZCte7FHtuS8wJgeYGrex28xNTd086Dik7vUMscQOa8y4DoGtCCSkAKlNwpgNtphjrC6MIHUkR6YWxxs6Sc5xqn222mmCRFzIt8lEdKx+ikCtg91qS2WpwVfBelJCiQJwvzixfI9cxZQWgiSJelKnwBElKYtDOb2MFbhmUigbReQBV0Cg4+qMXSxXSyGUn4UbF8l+7qdSGnTC0XLCmahIgUHLhLOhpVCtw4CzYXvLQWQbJNmxoCsOKAxSgBJno75avolkRw8iIAFcsdc02e9iyCd8tHwmeSSoKTowIgvscSGZUOA7PuCN5b2BX9mQM7S0wYhMNU74zgsPBj3HU7wguAfnxxjFQGBE6pwN+GjME9zHY7zGp8wVxMShYX9NXvEWD3HbwJf4giO4CFIQxXScH1/TM+04kkBiAAAAAElFTkSuQmCC"
          :icon-size="[25, 41]"
          :icon-anchor="[10, 41]"
          :popup-anchor="[1,-37]"
        />
      </l-marker>

      <l-marker
        v-if="currentPosition.latitude !== null && currentPosition.longitude !== null"
        :key="'zoom-' + zoom + '-pos'"
        :lat-lng="[currentPosition.latitude, currentPosition.longitude]"
      >
        <l-tooltip>Twoja pozycja</l-tooltip>
        <l-icon
          icon-url="/img/icons/marker.png"
          shadow-url="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAApCAQAAAACach9AAACMUlEQVR4Ae3ShY7jQBAE0Aoz/f9/HTMzhg1zrdKUrJbdx+Kd2nD8VNudfsL/Th///dyQN2TH6f3y/BGpC379rV+S+qqetBOxImNQXL8JCAr2V4iMQXHGNJxeCfZXhSRBcQMfvkOWUdtfzlLgAENmZDcmo2TVmt8OSM2eXxBp3DjHSMFutqS7SbmemzBiR+xpKCNUIRkdkkYxhAkyGoBvyQFEJEefwSmmvBfJuJ6aKqKWnAkvGZOaZXTUgFqYULWNSHUckZuR1HIIimUExutRxwzOLROIG4vKmCKQt364mIlhSyzAf1m9lHZHJZrlAOMMztRRiKimp/rpdJDc9Awry5xTZCte7FHtuS8wJgeYGrex28xNTd086Dik7vUMscQOa8y4DoGtCCSkAKlNwpgNtphjrC6MIHUkR6YWxxs6Sc5xqn222mmCRFzIt8lEdKx+ikCtg91qS2WpwVfBelJCiQJwvzixfI9cxZQWgiSJelKnwBElKYtDOb2MFbhmUigbReQBV0Cg4+qMXSxXSyGUn4UbF8l+7qdSGnTC0XLCmahIgUHLhLOhpVCtw4CzYXvLQWQbJNmxoCsOKAxSgBJno75avolkRw8iIAFcsdc02e9iyCd8tHwmeSSoKTowIgvscSGZUOA7PuCN5b2BX9mQM7S0wYhMNU74zgsPBj3HU7wguAfnxxjFQGBE6pwN+GjME9zHY7zGp8wVxMShYX9NXvEWD3HbwJf4giO4CFIQxXScH1/TM+04kkBiAAAAAElFTkSuQmCC"
          :icon-size="[30,38]"
          :tooltip-anchor="[20,0]"
          :icon-anchor="[16, 38]"
        />

      </l-marker>

      <l-control-attribution prefix="<span style='font-size: 9px;'><a href='https://leafletjs.com' target='_blank'>Leaflet</a> | &copy; <a href='https://www.openstreetmap.org/copyright' target='_blank'>OpenStreetMap</a> contributors</span>" />
    </l-map>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";

import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LControlAttribution,
  LIcon,
  LTooltip, LControl
} from "@vue-leaflet/vue-leaflet";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'PlacesMap',
  components: {
    LControl,
    LTooltip,
    LControlAttribution,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon
  },
  props: {
    placeMarkers: {
      type: Array,
      default: () => []
    },
  },
  mounted() {
    this.refreshCurrentPosition((p) => this.position = [p.latitude, p.longitude]);
  },
  computed: {
    ...mapGetters({
      isOrientationPortrait: 'device/isOrientationPortrait',
      currentPosition: 'geolocation/getCurrentPosition'
    }),
    filteredPlaceMarkers() {
      return this.placeMarkers.filter((m) => {
        return m.addressLongitude && m.addressLatitude
      })
    }
  },
  methods: {
    ...mapActions({
      refreshCurrentPosition: 'geolocation/refreshCurrentPosition'
    }),
    async locate() {
      this.isLocating = true;
      this.position = [this.position[0] - 0.00001, this.position[1]];
      await this.refreshCurrentPosition((p) => this.position = [p.latitude, p.longitude]);
      this.isLocating = false;
    },
    filterPlaces(placeKeys) {
      this.$emit('filterPlaces', placeKeys);
    },
  },
  data() {
    return {
      isLocating: false,
      zoom: 12,
      position: [52.409538, 16.931992], // default Poznań
    };
  }
}
</script>

<style lang="scss" scoped>
.map {
  height: 100%;
  position: relative;
}
</style>