const LEVEL_NONE = null;
const LEVEL_GUEST = 'guest';
const LEVEL_USER = 'user';

const auth = {
  namespaced: true,
  state: () => ({
    level: LEVEL_NONE,
    user: null,
    token: null,
  }),
  mutations: {
    setLevel(state, level) {
      state.level = level;
    },
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      if (token) {
        const now = new Date();
        const expiresIn = token.expiresIn < 20 ? Math.ceil(token.expiresIn * 0.5) : (token.expiresIn - 10);
        state.token = {
          createdAt: now.toISOString(),
          value: token.value,
          expiresIn: expiresIn,
          expiresAt: (new Date(now.getTime() + (expiresIn * 1000))).toISOString(),
        };
      } else {
        state.token = null;
      }
    },
    setStatus(state, status) {
      state.user.status = status.status
      state.user.statusDescription = status.description;
    }
  },
  actions: {
    loginUser({commit}, data) {
      commit('setToken', {value: data.token, expiresIn: data.tokenExpiresIn});
      commit('setLevel', LEVEL_USER);
      commit('setUser', data.user);
    },
    loginGuest({commit}) {
      commit('setLevel', LEVEL_GUEST);
      commit('setToken', null, null);
      commit('setUser', {});
    },
    logout({commit}) {
      commit('setLevel', LEVEL_NONE);
      commit('setUser', null);
      commit('setToken', null);
    },
    updateStatus({commit}, data) {
      commit('setStatus', data);
    }
  },
  getters: {
    isAuthenticated(state) {
      if (state.level === LEVEL_GUEST) {
        return true;
      }
      if (state.token !== null) {
        return new Date(state.token.expiresAt) > new Date();
      }
      return false;
    },
    isGuest(state) {
      return state.level === LEVEL_GUEST;
    },
    isUser(state) {
      return state.level === LEVEL_USER;
    },
    getToken(state) {
      return state.token;
    },
    getUser(state) {
      return state.user;
    },
    getUserStatus(state) {
      return state.user.status;
    },
    getUserStatusDescription(state) {
      return state.user?.statusDescription;
    },
    isUserStatusAvailable(state) {
      return state.user?.status === 'AVAILABLE';
    },
  }
}

export default auth