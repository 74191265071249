<template>
  <div class="text-center">
    <form class="mb-5">
      <div class="alert alert-danger p-2 alert-width" v-if="errorMessage">
        {{ errorMessage }}
      </div>
      <div class="alert alert-success p-2 alert-width" v-else-if="successMessage">
        {{ successMessage }}
      </div>
      <div class="mb-3 text-center">
        <router-link :to="{name: 'login'}" class="btn btn-sm btn-dark bg-secondary w-100">Powrót do logowania</router-link>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
  .alert-width {
    max-width: 217px;
  }
</style>

<script>
import {default as api} from '@/services/api';

export default {
  name: 'RegistrationView',
  data() {
    return {
      activationToken: this.$route.query?.token,
      errorMessage: '',
      successMessage: ''
    }
  },
  mounted() {
    this.submit();
  },
  methods: {
    validate() {
      if (!this.activationToken || !this.activationToken.trim()) {
        return 'Nieprawidłowy lub nieważny link aktywacyjny.'
      }

      return null;
    },
    submit() {
      this.errorMessage = this.validate();
      if (this.errorMessage) {
        return;
      }
      api.activateUser(
        this.activationToken,
      ).then(() => {
        this.successMessage = 'Pomyślnie aktywowano konto. Możesz się zalogować.'
      }).catch(async (error) => {
        const data = await error.json();
        this.errorMessage = data.message;
      });
    }
  }
}
</script>