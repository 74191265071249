const geolocation = {
  namespaced: true,
  state: () => ({
    position: {
      latitude: null,
      longitude: null
    }
  }),
  mutations: {
    setPosition(state, position) {
      state.position = position;
    },
  },
  actions: {
    refreshCurrentPosition({commit}, callback) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const pos = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          commit('setPosition', pos);
          if (callback) {
            callback(pos);
          }
        }, null, {
          enableHighAccuracy: true,
          timeout: 15000,
          maximumAge: 0
        });
      }
    },
  },
  getters: {
    getCurrentPosition(state) {
      return state.position;
    }
  }
}

export default geolocation