import { createStore } from 'vuex'
import auth from './auth'
import device from './device'
import geolocation from './geolocation'

const store= createStore({
  modules: {
    auth,
    device,
    geolocation,
  },
  mutations: {
    initStore(state) {
      if (!localStorage) {
        return;
      }
      if (localStorage.getItem('store')) {
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem('store')))
        );
      }
    }
  }
});

store.subscribe((mutation, state) => {
  if (!localStorage) {
    return;
  }

  if (mutation.type.startsWith('auth/')) {
    let store = {
      auth: state.auth
    }
    localStorage.setItem('store', JSON.stringify(store));
  }
});

export default store