<template>
  <div>
    <img
      v-if="qrCodeRef"
      :class="imageClass"
      :src="qrCodeRef"
      :crossorigin="options.imageOptions.crossOrigin"
      alt=""
    />

    <div
      v-else
      :class="imageClass"
      ref="qrCodeSvgRef"
    ></div>

    <button
      v-if="props.download"
      type="button"
      :class="props.downloadClass"
      @click="download"
    >
      {{ props.download === true ? 'Download' : props.download }}
    </button>
  </div>
</template>

<script setup>
import QRCodeStyling from 'styled-qr-code';
import { onMounted, ref, defineProps, watch } from 'vue';

const qrCodeRef = ref(null);
const qrCodeSvgRef = ref(null);

const props = defineProps({
  type: {
    type: String,
    default: 'png',
  },
  backgroundOptions: {
    type: Object,
    default: () => {},
  },
  cornersDotOptions: {
    type: Object,
    default: () => {},
  },
  cornersSquareOptions: {
    type: Object,
    default: () => {},
  },
  dotsOptions: {
    type: Object,
    default: () => {},
  },
  download: {
    type: [Boolean, String],
    default: false
  },
  downloadClass: {
    type: String,
    default: 'btn btn-primary'
  },
  downloadOptions: {
    type: Object,
    default: () => {},
  },
  image: {
    type: String,
    default: null,
  },
  imageClass: {
    type: String,
    default: null,
  },
  imageOptions: {
    type: Object,
    default: () => {},
  },
  margin: {
    type: Number,
    default: 0,
  },
  height: {
    type: Number,
    default: 400,
  },
  width: {
    type: Number,
    default: 400,
  },
  value: String,
  qrOptions: {
    type: Object,
    default: () => {},
  },
});

const options = {
  width: props.width,
  height: props.height,
  type: props.type === 'svg' ? 'svg' : 'canvas',
  data: props.value,
  image: props.image,
  margin: props.margin,
  qrOptions: {
    ...{
      typeNumber: 0,
      mode: 'Byte',
      errorCorrectionLevel: 'H',
    },
    ...props.qrOptions
  },
  imageOptions: {
    ...{
      hideBackgroundDots: true,
      imageSize: 0.2,
      margin: 0,
      crossOrigin: 'anonymous',
    },
    ...props.imageOptions
  },
  dotsOptions: {
    ...{
      color: '#000000',
      type: 'square',
    },
    ...props.dotsOptions
  },
  backgroundOptions: {
    ...{
      color: '#ffffff',
    },
    ...props.backgroundOptions,
  },
  cornersSquareOptions: {
    ...{
      color: '#000000',
      type: 'square',
    },
    ...props.cornersSquareOptions
  },
  cornersDotOptions: {
    ...{
      color: '#000000',
      type: 'square',
    },
    ...props.cornersDotOptions
  },
};

const downloadOptions = {
  ...{
    name: `qrcode`,
    extension: props.type,
  },
  ...props.downloadOptions
};

const qrCode = new QRCodeStyling(options);

onMounted(async () => {
  if (props.type === 'svg') {
    qrCodeRef.value = null;
    qrCode.append(qrCodeSvgRef.value);
  } else {
    qrCodeRef.value = await qrCode.toDataUrl(props.type);
  }
});

watch(
  () => props.value,
  (newValue) => {
    qrCode.update({ ...options, data: newValue });
  }
);

const download = () => {
  qrCode.download(downloadOptions);
}
</script>
