<template>
  <div :id="id"
       :aria-labelledby="id + 'Label'"
       :ref="id"
       aria-hidden="true"
       data-bs-keyboard="false"
       class="modal modal-lg fade"
       tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-0">
        <div v-if="$slots.header" class="modal-header">
          <slot name="header">
            <h1 class="modal-title fs-5" id="id + 'Label'">{{ title }}</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </slot>
        </div>
        <div class="modal-body">
          <slot name="body" />
        </div>
        <div v-if="$slots.footer" class="modal-footer">
          <slot name="footer">
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { default as Modal } from 'bootstrap/js/dist/modal';

export default {
  name: "UiModal",
  props: {
    id: {
      type: String,
      default() {
        return 'modal-' + btoa(Math.random().toString()).substring(10,15);
      }
    },
    modelValue: Boolean,
    title: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      modal: null,
    }
  },
  methods: {
    ref() {
      return this.$refs[this.id];
    },
    instance() {
      if (!this.modal) {
        this.modal = new Modal(this.ref(), {});
      }
      return this.modal;
    },
    open() {
      this.$emit('update:modelValue', true);
    },
    close() {
      this.$emit('update:modelValue', false);
    }
  },
  mounted() {
    let ref = this.ref();
    if (ref) {
      ref.addEventListener('shown.bs.modal', this.open);
      ref.addEventListener('hidden.bs.modal', this.close);
    }
  },
  unmounted() {
    let ref = this.ref();
    if (ref) {
      ref.removeEventListener('shown.bs.modal', this.open);
      ref.removeEventListener('hidden.bs.modal', this.close);
    }
  },
  watch: {
    modelValue(after, before) {
      if (before && !after) {
        this.instance().hide();
      } else if (!before && after) {
        this.instance().show();
      }
    }
  }
}
</script>