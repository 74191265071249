<template>
  <div class="id-basic">
    <div class="photo">
      <ui-loader v-if="loadingPhoto" bg-color="white" :bg-opacity="0.5" />
      <div
          v-if="userPhoto"
          class="bg-image"
          :style="{backgroundImage: 'url(' + userPhoto + ')'}"
      ></div>
      <div v-else class="empty d-flex h-100 align-items-center text-center">
          <div class="w-100">(fotografia)<div v-if="editable">Kliknij aby wgrać zdjęcie</div></div>
      </div>
      <div v-if="error" :class="{'bg-opacity-75': userPhoto}" class="errors position-absolute d-flex h-100 align-items-center text-center text-danger bg-white">
        Wgranie zdjęcia się nie powiodło: <br />
        {{ error }}
      </div>
      <label class="btn-change-photo">
          <input type="file" ref="photo" @change="selectPhoto" accept="image/*" />
      </label>
    </div>
    <div class="title">
      LEGITYMACJA<br>TOCZERSKA
    </div>
    <div class="value sign">
      {{ user.firstName }} {{ user.lastName }}
      <div class="label">podpis Toczersa</div>
    </div>
    <div class="value no">
      {{ user.no }}
      <div class="label">numer Toczersa</div>
    </div>
    <div class="value join-date">
      {{ userJoinDate }}
      <div class="prefix">data dołączenia</div>
      <div class="label">dzień, miesiąc, rok</div>
    </div>
    <div class="value place">
      {{ user.joinDescription }}
      <div class="label">pierwszy raz toczony w:</div>
    </div>
    <div class="value stamp">
      <div class="label">pieczęć podłużna Toczersów</div>
    </div>
  </div>
</template>

<script setup>
import moment from 'moment';
import { default as api } from '@/services/api';
import { default as store } from '@/store';
import {defineProps, ref, computed} from 'vue';
import UiLoader from "@/components/Ui/UiLoader.vue";

const props = defineProps(['user', 'editable']);

const user = ref(props.user);
const userJoinDate = computed(() => {
  return user.value.joinDate ? moment(user.value.joinDate, 'YYYY-MM-DD').format('DD.MM.YYYY') : '';
})
const userPhoto = computed(() => props.user?.photo?.url);

let loadingPhoto = ref(false);
let error = ref('');

const selectPhoto = async (event) => {
  try {
    loadingPhoto.value = true;
    error.value = '';
    const file = await api.uploadFile('USER_PHOTO', event.target.files[0]);
    await api.changeUserPhoto(file.id);
    const data = await api.refreshToken();
    await store.dispatch('auth/loginUser', data.refreshUserToken);
  } catch (e) {
    if (e instanceof Response) {
      error.value = (await e.json())?.errors[0] || '';
    } else {
      console.log(e);
    }
  } finally {
    loadingPhoto.value = false;
  }
};
</script>
