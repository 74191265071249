<template>
  <div class="form-container">
    <form class="mb-5" @submit="token ? submitPasswordChange : submitPasswordRecover">
      <div class="alert alert-danger small p-2 w-100" v-if="errorMessage">
        {{ errorMessage }}
      </div>
      <div class="alert alert-success small p-2 w-100" v-else-if="successMessage">
        {{ successMessage }}
      </div>
      <div v-if="token">
        <div class="mb-3">
          <input
            v-model="password"
            type="password"
            placeholder="Hasło"
            class="form-control"
            :class="{ 'is-invalid': formErrors.password }"
            @change="formErrors.password = undefined"
          />
          <div v-if="formErrors.password" class="invalid-feedback">
            {{ formErrors.password }}
          </div>
        </div>
        <div class="mb-3">
          <input
            v-model="passwordRepeated"
            type="password"
            placeholder="Powtórz hasło"
            class="form-control"
            :class="{ 'is-invalid': formErrors.passwordRepeated }"
            @change="formErrors.passwordRepeated = undefined"
          />
          <div v-if="formErrors.passwordRepeated" class="invalid-feedback">
            {{ formErrors.passwordRepeated }}
          </div>
        </div>
      </div>
      <div v-else class="mb-3">
        <input v-model="email" type="text" placeholder="E-mail" class="form-control">
      </div>
      <div class="mb-3 mt-5 text-center">
        <button v-if="token" @click="submitPasswordChange" type="submit" class="btn btn-outline-success  w-100" >Zapisz</button>
        <button v-else @click="submitPasswordRecover" type="submit" class="btn btn-outline-success w-100" >Odzyskaj hasło</button>
      </div>
      <div class="mb-3 text-center">
        <router-link :to="{name: 'login'}" class="btn btn-dark btn-sm w-100">Powrót do logowania</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import {default as api} from '@/services/api';
import {object, string, ref} from 'yup';

const recoverPasswordSchema = object({
  email: string().email('Nieprawidłowy adres email').required('Adres e-email nie może być pusty'),
});

const passwordChangeSchema = object({
  password: string().min(7, 'Hasło musi mieć przynajmniej 7 znaków').required('Hasło nie może być puste'),
  passwordRepeated: string().required('Hasło nie może być puste').oneOf([ref('password'), null], 'Hasła muszą się zgadzać'),
});

export default {
  name: 'RegistrationView',
  data() {
    return {
      token: this.$route.query?.token,
      formErrors: {},
      email: '',
      password: '',
      passwordRepeated: '',
      errorMessage: '',
      successMessage: ''
    }
  },
  methods: {
    validatePasswordChange() {
      this.formErrors = {};

      try {
        passwordChangeSchema.validateSync({
          password: this.password,
          passwordRepeated: this.passwordRepeated
        }, { abortEarly: false });
      } catch (error) {
        error.inner.forEach(err => {
          this.formErrors[err.path] = err.message;
        });
      }

      const errors = Object.values(this.formErrors);
      if (errors.length > 0) {
        return 'Formularz zawiera błędy';
      }

      return null;
    },
    validatePasswordRecover() {
      try {
        recoverPasswordSchema.validateSync({
          email: this.email
        });
      } catch (error) {
        return error.message
      }

      return null;
    },
    submitPasswordRecover(e) {
      e.preventDefault();
      this.successMessage = '';
      this.errorMessage = this.validatePasswordRecover();
      if (this.errorMessage) {
        return;
      }
      api.passwordRecover(
        this.email
      ).then(() => {
        this.email = '';
        this.successMessage = 'Na podany adres została wysłana wiadomość z dalszymi instrukcjami.'
      }).catch(async (error) => {
        const data = await error.json();
        this.errorMessage = data.message;
      });
    },
    submitPasswordChange(e) {
      e.preventDefault();
      this.successMessage = '';
      this.errorMessage = this.validatePasswordChange();
      if (this.errorMessage) {
        return;
      }
      api.passwordChange(
        this.token,
        this.password
      ).then(() => {
        this.password = '';
        this.passwordRepeated = '';
        this.successMessage = 'Hasło zostało pomyślnie zmienione.'
      }).catch(async (error) => {
        const data = await error.json();
        this.errorMessage = data.message;
      });
    }
  }
}
</script>