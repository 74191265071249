<template>
  <div class="profile p-3">
    <nav class="nav nav-pills justify-content-center mb-4 px-4">
      <a :class="'text-sm-center nav-link rounded-0 mx-2 ' + (tab === 'id' ? 'active' : '')" href="#"
         @click="changeTab('id')">Legitymacja</a>
      <a :class="'text-sm-center nav-link rounded-0 mx-2 ' + (tab === 'qr' ? 'active' : '')" href="#"
         @click="changeTab('qr')">Kod QR</a>
      <a :class="'text-sm-center nav-link rounded-0 mx-2 ' + (tab === 'account' ? 'active' : '')" href="#"
         @click="changeTab('account')">Dane konta</a>
    </nav>
    <div class="row justify-content-center">
      <div v-if="tab === 'id'" class="col text-center">
        <user-identifier :user="user" :editable="true" />
      </div>
      <div v-else-if="tab === 'qr'" class="col-sm-12 col-md-6 col-lg-5 col-xl-3">
        <div class="text-center">
          <qr-code
            image-class="user-qr-code"
            image="/img/icons/android-chrome-192x192.png"
            :value="qrUid"
          />
          Twój kod QR
        </div>
      </div>
      <div v-else-if="tab === 'account'" class="col">
        <div v-if="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
        <div v-else-if="successMessage" class="alert alert-success">
          {{ successMessage }}
        </div>
        <form class="row" @submit="submit" autocomplete="off">
          <div class="mb-3 col-md-6">
            <label for="firstName" class="form-label">Imię</label>
            <input
              v-model="formData.firstName"
              id="firstName"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': formErrors.firstName }"
              @change="formErrors.firstName = undefined"
            />
            <div v-if="formErrors.firstName" class="invalid-feedback">
              {{ formErrors.firstName }}
            </div>
          </div>
          <div class="mb-3 col-md-6">
            <label for="lastName" class="form-label">Nazwisko</label>
            <input
              v-model="formData.lastName"
              id="lastName"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': formErrors.lastName }"
              @change="formErrors.lastName = undefined"
            />
            <div v-if="formErrors.lastName" class="invalid-feedback">
              {{ formErrors.lastName }}
            </div>
          </div>
          <div class="mb-3 col-md-6">
            <label for="email" class="form-label">Adres e-mail</label>
            <input
              v-model="formData.email"
              id="email"
              type="email"
              class="form-control"
              :class="{ 'is-invalid': formErrors.email }"
              @change="formErrors.email = undefined"
            />
            <div v-if="formErrors.email" class="invalid-feedback">
              {{ formErrors.email }}
            </div>
          </div>
          <div class="mb-3 col-md-6">
            <label for="joinDate" class="form-label">Data dołączenia</label>
            <input
              v-model="formData.joinDate"
              id="joinDate"
              type="date"
              class="form-control"
              :class="{ 'is-invalid': formErrors.joinDate }"
              @change="formErrors.joinDate = undefined"
            />
            <div v-if="formErrors.joinDate" class="invalid-feedback">
              {{ formErrors.joinDate }}
            </div>
          </div>
          <div class="mb-3 col-md-6">
            <label for="joinDescription" class="form-label">Miejsce pierwszego toczenia</label>
            <input
              v-model="formData.joinDescription"
              id="joinDescription"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': formErrors.joinDescription }"
              @change="formErrors.joinDescription = undefined"
            />
            <div v-if="formErrors.joinDescription" class="invalid-feedback">
              {{ formErrors.joinDescription }}
            </div>
          </div>
          <div class="text-center my-4">
            <button type="submit" class="btn btn-dark">Zapisz</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { default as api } from '@/services/api';
import {mapActions, mapGetters} from "vuex";
import {object, string, date} from "yup";
import QrCode from "@/components/Misc/QrCode.vue";
import moment from "moment";
import UserIdentifier from "@/components/Profile/UserIdentifier.vue";

const joinMaxDate = moment();
const joinMinDate = moment('2020-01-01', 'YYYY-MM-DD');

const userSchema = object({
  firstName: string().required('Imię nie może być puste'),
  lastName: string(),
  email: string().email('Nieprawidłowy adres email').required('Adres e-email nie może być pusty'),
  joinDate: date()
    .nullable()
    .required('Data dołączenia jest wymagana')
    .typeError('Data dołączenia jest wymagana')
    .min(joinMinDate.format('YYYY-MM-DD'), 'Data nie może być wczesniejsza od ' + joinMinDate.format('DD.MM.YYYY'))
    .max(joinMaxDate.format('YYYY-MM-DD'), 'Data nie może być póżniejsza od dzisiejszej.'),
  joinDescription: string().max(80, (v) => `Pole może zawierać maksymalnie ${v.max} znaków`),
});

export default {
  name: 'RegistrationView',
  components: {
    UserIdentifier,
    QrCode,
  },
  data() {
    return {
      tab: 'id',
      errorMessage: null,
      successMessage: null,
      formErrors: {},
      formData: {...(this.getUser() || {})}
    }
  },
  computed: {
    qrUid() {
      const user = this.getUser();
      if (!user) {
        return '';
      }
      return 'UID:' + user.qr.toUpperCase().replaceAll('-', '');
    },
    user() {
        return this.getUser();
    }
  },
  methods: {
    ...mapGetters({
      getUser: 'auth/getUser',
    }),
    ...mapActions({
      loginUser: 'auth/loginUser'
    }),
    validate() {
      this.formErrors = {};

      try {
        userSchema.validateSync(this.formData, { abortEarly: false });
      } catch (error) {
        error.inner.forEach(err => {
          this.formErrors[err.path] = err.message;
        });
      }

      const errors = Object.values(this.formErrors);
      if (errors.length > 0) {
        return 'Formularz zawiera błędy';
      }

      return null;
    },
    submit(e) {
      e.preventDefault();
      this.successMessage = null;
      this.errorMessage = this.validate();
      if (this.errorMessage) {
        return;
      }
      api.updateProfile(
        this.formData.email,
        this.formData.firstName,
        this.formData.lastName,
        this.formData.joinDate,
        this.formData.joinDescription,
      ).then(() => {
        api.refreshToken().then((data) => {
          this.loginUser(data.refreshUserToken);
          this.formData = this.getUser();
          this.successMessage = 'Pomyślnie zapisano zmiany.';
        })
      }).catch(async (error) => {
        const data = await error.json();
        this.errorMessage = data.message;
      });
    },
    changeTab(tab) {
      this.errorMessage = null;
      this.successMessage = null;
      this.formErrors = {};
      this.formData = {...(this.getUser() || {})}
      this.tab = tab;
    }
  }
}
</script>

<style lang="scss" scoped>
  .nav-link {
    --bs-nav-pills-link-active-bg: transparent;
    --bs-nav-pills-link-active-color: #000;
    --bs-nav-link-hover-color: #333;
    color: #000;
    &.active {
      font-weight: bold;
      border-bottom: 3px #00ce1b solid;
    }
  }
</style>