<template>
  <div class="events">
    <div class="row h-100 m-0 p-0">
      <div :class="'p-0 ' + (isOrientationPortrait ? 'h-50 col-12' : 'h-100 col-8')">
        <events-map
          :event-markers="eventMarkers"
          @filter-events="filterEvents"
          @filter-events-period="filterEventsPeriod"
        />
      </div>
      <div :class="'p-0 overflow-scroll-y ' + (isOrientationPortrait ? 'h-50 col-12' : 'h-100 col-4')">
        <ui-loader v-if="loading" />
        <ul class="list-group" v-else-if="filteredEvents.length > 0">
          <li class="list-group-item py-3 rounded-0" v-for="(event, i) in filteredEvents" :key="i"
            :style="'background-image: linear-gradient(90deg, rgba(254,254,254,1) 60%, rgba(255,255,255,0.35) 100%), url('+event.thumbnailUrl+');'">
            <h5>{{ event.title }}</h5>
            <div class="row">
              <div class="col-6">
                <strong>{{ event.locationName }}</strong>
                <br>
                {{ event.address }}
              </div>
              <div class="col-6">
                {{ event.startAt === event.endAt ? event.startAt : event.startAt + ' - ' + event.endAt }}
              </div>
              <div class="col-12">
                <button
                  type="button"
                  class="btn btn-sm btn-dark mt-3 w-100"
                  @click="setEventContext(i)"
                >
                  Pokaż Szczegóły
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div v-else class="p-3 text-center">
          Brak wydarzeń do wyświetlenia.
        </div>
      </div>
    </div>
    <ui-modal v-model="eventModalShow">
      <template #body v-if="eventContext">
        <div class="row">
          <div class="col-10">
            <h2>{{ eventContext.title }}</h2>
          </div>
          <div class="col-2 text-end pl-0">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
        </div>
        <img :src="eventContext.thumbnailUrl" class="card-img-top" alt="">
        <div class="event-description small" v-html="nl2br(eventContext.description)"></div>
      </template>
    </ui-modal>
  </div>
</template>

<script>
import EventsMap from "@/components/Events/EventsMap.vue";
import {mapGetters} from "vuex";
import UiModal from "@/components/Ui/UiModal.vue";
import UiLoader from "@/components/Ui/UiLoader.vue";
import {default as api} from '@/services/api';

export default {
  name: 'EventsView',
  components: {
    UiLoader,
    UiModal,
    EventsMap
  },
  computed: {
    ...mapGetters({
      isOrientationPortrait: 'device/isOrientationPortrait'
    }),
    filteredEvents() {
      if (this.filterEventsKeys.length > 0) {
        return this.events.filter((e, key) => this.filterEventsKeys.includes(key));
      }
      return this.events;
    }
  },
  data() {
    return {
      eventContext: null,
      eventModalShow: false,
      eventMarkers: [],
      events: [],
      loading: true,
      filterEventsKeys: [],
    }
  },
  mounted() {
    this.loadEvents('week');
  },
  methods: {
    async loadEvents(period) {
      this.loading = true;
      const data = await api.getEvents(period);

      this.eventMarkers = data.eventMarkers || [];
      this.events = data.events || [];
      this.loading = false;
    },
    filterEvents(eventKeys) {
      this.filterEventsKeys = eventKeys;
    },
    filterEventsPeriod(period) {
      this.filterEvents([]);
      this.loadEvents(period);
    },
    setEventContext(eventKey) {
      this.eventModalShow = true;
      this.eventContext = this.filteredEvents.at(eventKey);
    },
    nl2br(description) {
      return description
        .replace(/<div[^>]*>\s+<\/div>/g, '<div></div>')
        .replace(/<\/div>\s+<div/g, '</div><div')
        .replace(/\r\n/g, '<br>\r\n');
    }
  }
}
</script>

<style lang="scss" scoped>
.events {
  height: calc(100vh - 64px);
  position: relative;
  overflow: hidden;
  .list-group-item {
    background-size: auto;
    background-position: center center;
    background-repeat: no-repeat;
    border: none;
    border-bottom: 1px solid #000;
  }
}
</style>
